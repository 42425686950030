import { Injectable, inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { catchError, debounceTime, finalize, map, mergeMap, of, tap } from 'rxjs';
import { MessageService } from '@corelabs/angular-messages';
import { LoadersFacade } from '@app/store/loader';
import { userManagementActions } from '../actions/user-management.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class UserManagementEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loadersFacade = inject(LoadersFacade);
    private readonly messageService = inject(MessageService);
    private readonly translateService = inject(TranslateService);

    changePassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userManagementActions.changePassword),
            mergeMap(({ value }) => {
                this.loadersFacade.add('change-password');
                return this.httpService.changePassword(value).pipe(
                    map(() => userManagementActions.changePasswordSuccess()),
                    catchError(() => of(userManagementActions.changePasswordError())),
                    finalize(() => this.loadersFacade.delete('change-password')),
                );
            }),
        );
    });

    changeLanguage$ = createEffect(() => {
        return this.actions$.pipe(
            debounceTime(300),
            ofType(userManagementActions.changeLanguage),
            mergeMap(({ value }) => {
                return this.httpService.changeLanguage(value).pipe(
                    map(() => userManagementActions.changeLanguageSuccess({ value })),
                    catchError(() => of(userManagementActions.changeLanguageError())),
                );
            }),
        );
    });

    editUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userManagementActions.editUser),
            mergeMap(({ value }) => {
                return this.httpService.editUser(value).pipe(
                    map(() => userManagementActions.editUserSuccess({ value })),
                    catchError((errorResponse: HttpErrorResponse) => of(userManagementActions.editUserError({ errorResponse }))),
                );
            }),
        );
    });

    editUserError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(userManagementActions.editUserError),
                tap(({ errorResponse }) => {
                    const errors = errorResponse.error?.errors;
                    if (errors === undefined || !Array.isArray(errors)) {
                        this.messageService.add(this.translateService.instant('request-error'), 'danger');

                        return;
                    }

                    errors.forEach((errorMsg) => {
                        switch (errorMsg) {
                            case 'validation.nin.format':
                            case 'validation.nin.invalid': {
                                this.messageService.add(this.translateService.instant('user.edit.invalid-pesel'), 'danger');
                                break;
                            }

                            case 'validation.vatin.format':
                            case 'validation.vatin.invalid': {
                                this.messageService.add(this.translateService.instant('user.edit.invalid-vatin'), 'danger');
                                break;
                            }

                            default: {
                                this.messageService.add(this.translateService.instant('request-error'), 'danger');
                                break;
                            }
                        }
                    });
                }),
            );
        },
        { dispatch: false },
    );
}
