import { Pipe, PipeTransform, inject } from '@angular/core';
import { MediaFacade } from '@app/store/media';
import { Observable, of } from 'rxjs';

@Pipe({
    name: 'fileUrl',
    standalone: true,
})
export class FileUrlPipe implements PipeTransform {
    private mediaFacade = inject(MediaFacade);

    transform(id: string | null): Observable<string | null> {
        if (id === null) {
            return of(null);
        }

        this.mediaFacade.getFile(id);

        return this.mediaFacade.fileUrl$(id);
    }
}
