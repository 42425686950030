import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { UserFacade } from '../facades/user.facade';

export const userCleanup = <T>(): CanDeactivateFn<T> => {
    return () => {
        const userFacade = inject(UserFacade);

        userFacade.clear();
        return true;
    };
};
