import { createReducer, on } from '@ngrx/store';
import { userManagementActions } from '@app/store/user-management';
import { socialLinksActions } from '@app/store/social-links';
import { userActions } from '../actions/user.actions';
import { User, UserDetails } from '../models';

export interface UserState {
    loaded: boolean | null;
    user: User | null;
    detailsLoaded: boolean | null;
    userDetails: UserDetails | null;
}

export const initialState: UserState = {
    loaded: null,
    user: null,
    detailsLoaded: null,
    userDetails: null,
};

export const userReducer = createReducer(
    initialState,
    on(userActions.getUser, (state): UserState => ({ ...state, loaded: null })),
    on(userActions.getUserSuccess, (state, payload): UserState => ({ ...state, loaded: true, user: payload.user })),
    on(userActions.getUserError, (state): UserState => ({ ...state, loaded: false })),

    on(userActions.getUserDetails, (state): UserState => ({ ...state, detailsLoaded: null })),
    on(userActions.getUserDetailsSuccess, (state, payload): UserState => ({ ...state, detailsLoaded: true, userDetails: payload.userDetails })),
    on(userActions.getUserDetailsError, (state): UserState => ({ ...state, detailsLoaded: false })),

    on(userManagementActions.editUserSuccess, (state, { value }): UserState => {
        if (!state.user) {
            return state;
        }

        const user: User = {
            ...state.user,
            firstName: value.firstName,
            lastName: value.lastName,
            pseudonym: value.pseudonym,
            phoneNumber: value.phoneNumber,
            avatar: value.avatar,
        };

        const userDetails: UserDetails = {
            ...user,
            contractorData: value.contractorData,
            contractorType: value.contractorData.type,
        };

        return { ...state, user, userDetails };
    }),

    on(userManagementActions.changeLanguageSuccess, (state, payload): UserState => {
        if (!state.user) {
            return state;
        }

        const user: User = { ...state.user, language: payload.value };

        return {
            ...state,
            user,
            userDetails: state.userDetails ? { ...state.userDetails, ...user } : null,
        };
    }),

    on(socialLinksActions.editSocialLinksSuccess, (state): UserState => {
        if (!state.user) {
            return state;
        }

        const user: User = { ...state.user, verificationStatus: 'pending' };

        return {
            ...state,
            user,
            userDetails: state.userDetails ? { ...state.userDetails, ...user } : null,
        };
    }),

    on(userActions.clear, (): UserState => ({ ...initialState })),
);
