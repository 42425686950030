import { CompanyData, IndividualData } from '@app/store/models';
import { ContractorType } from '@app/store/types';
import { User } from './user';
import { UserDetailsDto } from './user-details.dto';

export class UserDetails extends User {
    contractorType: ContractorType;
    contractorData: CompanyData | IndividualData;

    constructor(userDto: UserDetailsDto) {
        super(userDto);

        this.contractorType = userDto.contractorType;

        switch (userDto.contractorType) {
            case 'company':
                this.contractorData = new CompanyData(userDto.contractorData);
                break;

            case 'individual':
                this.contractorData = new IndividualData(userDto.contractorData);
                break;
        }
    }
}
