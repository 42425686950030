import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberWithSpaces',
    standalone: true,
})
export class NumberWithSpacesPipe implements PipeTransform {
    transform(value: string | number): string {
        return this.numberWithSpaces(value);
    }

    numberWithSpaces(x: number | string) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
}
