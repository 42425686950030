<section class="w-full h-full card-radius-{{ radius }}" [ngClass]="{ 'p-4 bg-gradient-gray-40': !unstyled }">
    <div *ngIf="title || titleIcon" class="inline-flex gap-2 items-center mb-4">
        <app-icon *ngIf="titleIcon" [name]="titleIcon" />

        <ng-container *ngIf="title" [ngSwitch]="titleSize">
            <h2 *ngSwitchCase="'h2'">{{ title }}</h2>
            <h3 *ngSwitchCase="'h3'">{{ title }}</h3>
        </ng-container>
    </div>

    <ng-content />
</section>
