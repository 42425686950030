import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, filter, skip, takeUntil, tap, timer } from 'rxjs';
import { DaysLeftPipe, HoursLeftPipe, MinutesLeftPipe } from '@app/shared/pipes';
import { CardComponent } from '../../card/card.component';

@Component({
    selector: 'app-countdown',
    templateUrl: './countdown.component.html',
    styleUrls: ['./countdown.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, DaysLeftPipe, HoursLeftPipe, MinutesLeftPipe, CardComponent],
})
export class CountdownComponent implements OnInit {
    @Input({ required: true }) date!: Date;

    readonly timeLeft$ = new BehaviorSubject(0);

    private readonly stopCondition$ = this.timeLeft$.pipe(
        skip(1),
        filter((ms) => ms <= 0),
    );

    ngOnInit() {
        timer(0, 1000)
            .pipe(
                tap(() => this.timeLeft$.next(this.getTimeLeft())),
                takeUntil(this.stopCondition$),
            )
            .subscribe();
    }

    private getTimeLeft() {
        const currentTimestamp = new Date().getTime();
        const countdownTimestap = this.date.getTime();

        return countdownTimestap > currentTimestamp ? countdownTimestap - currentTimestamp : 0;
    }
}
