import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { SocialLinks } from '../models';
import { ApiResponse } from '../../../shared/models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getSocialLinks() {
        return this.httpClient.get<ApiResponse<SocialLinks>>('api/v1/users/me/social-media-links').pipe(map((res) => res.data));
    }

    editSocialLinks(value: SocialLinks) {
        return this.httpClient.put('api/v1/users/me/social-media-links', value);
    }
}
