import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { MediaFacade } from '../facades/media.facade';

export const mediaCleanup: CanDeactivateFn<unknown> = () => {
    const mediaFacade = inject(MediaFacade);
    mediaFacade.clear();

    return true;
};
