import { Pipe, PipeTransform, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MediaFacade } from '@app/store/media';
import { FileInfo } from '@app/store/media';

@Pipe({
    name: 'file',
    standalone: true,
})
export class FilePipe implements PipeTransform {
    private mediaFacade = inject(MediaFacade);

    transform(id: string | null): Observable<FileInfo | null> {
        if (id === null) {
            return of(null);
        }

        this.mediaFacade.getFile(id);

        return this.mediaFacade.file$(id);
    }
}
