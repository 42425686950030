import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, finalize, map, mergeMap, exhaustMap } from 'rxjs';
import { userActions } from '../actions/user.actions';
import { HttpService } from '../services/http.service';
import { LoadersFacade } from '@app/store/loader';

@Injectable()
export class UserEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loadersFacade = inject(LoadersFacade);

    getUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.getUser),
            mergeMap(() => {
                this.loadersFacade.add('get-user');

                return this.httpService.getUser().pipe(
                    map((user) => userActions.getUserSuccess({ user })),
                    catchError(() => of(userActions.getUserError())),
                    finalize(() => this.loadersFacade.delete('get-user')),
                );
            }),
        );
    });

    getUserDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.getUserDetails),
            exhaustMap(() => {
                this.loadersFacade.add('get-user-details');

                return this.httpService.getUserDetails().pipe(
                    map((userDetails) => userActions.getUserDetailsSuccess({ userDetails })),
                    catchError(() => of(userActions.getUserDetailsError())),
                    finalize(() => this.loadersFacade.delete('get-user-details')),
                );
            }),
        );
    });
}
