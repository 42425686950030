import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { USER_MANAGEMENT_FEATURE_KEY } from './keys';
import { userManagementReducer } from './reducers/user-management.reducer';
import { UserManagementEffects } from './effects/user-management.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(USER_MANAGEMENT_FEATURE_KEY, userManagementReducer), EffectsModule.forFeature([UserManagementEffects])],
})
export class UserManagementStoreModule {}
