import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@app/shared/icons/icon/icon.component';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-desktop-breadcrumbs',
    standalone: true,
    imports: [CommonModule, IconComponent, RouterModule],
    templateUrl: './desktop-breadcrumbs.component.html',
    styleUrls: ['./desktop-breadcrumbs.component.scss'],
})
export class DesktopBreadcrumbsComponent {
    @Input({ required: true }) text!: string;
    @Input() backBtnRedirect?: string;
}
