import { Pipe, PipeTransform, inject } from '@angular/core';
import { DictionaryItem } from '../models/dictionary-item';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@app/i18n';
import { map } from 'rxjs';

@Pipe({
    name: 'dictionary$',
    standalone: true,
})
export class DictionaryPipe implements PipeTransform {
    languageService = inject(LanguageService);
    translateService = inject(TranslateService);

    transform(items: DictionaryItem[]) {
        return this.languageService.language$.pipe(
            map(() => {
                return items.map(({ translationKey, value }) => ({
                    name: <string>this.translateService.instant(translationKey),
                    value,
                }));
            }),
        );
    }
}
