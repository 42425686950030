import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './icon/icon.component';

@NgModule({
    imports: [CommonModule, IconComponent],
    declarations: [],
    exports: [IconComponent],
})
export class IconsModule {}
