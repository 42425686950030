import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, map, startWith } from 'rxjs';
import { MobileStep, MobileStepWithLink } from '../models/mobile-step';

@Component({
    selector: 'app-mobile-stepper',
    templateUrl: './mobile-stepper.component.html',
    styleUrls: ['./mobile-stepper.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule],
})
export class MobileStepperComponent {
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);

    @Input({ required: true })
    get steps(): MobileStepWithLink[] {
        return this._steps;
    }
    set steps(value: MobileStep[]) {
        this._steps = value.map((value) => ({ ...value, fullPath: this.createLink(value.path) }));
    }
    private _steps: MobileStepWithLink[] = [];

    readonly activeStepIndex$ = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router),
        map(() => {
            const currentStepPath = this.getStepRoute().routeConfig?.path;

            return this.steps.findIndex(({ path }) => path === currentStepPath);
        }),
    );

    private getStepRoute() {
        return this.activatedRoute.firstChild ?? this.activatedRoute;
    }

    private createLink(path: string) {
        return this.router.createUrlTree([path], { relativeTo: this.getStepRoute().parent }).toString();
    }
}
