import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@app/shared/icons/icon/icon.component';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '../button';

@Component({
    selector: 'app-mobile-breadcrumbs',
    standalone: true,
    imports: [CommonModule, IconComponent, RouterModule, ButtonComponent],
    templateUrl: './mobile-breadcrumbs.component.html',
    styleUrls: ['./mobile-breadcrumbs.component.scss'],
})
export class MobileBreadcrumbsComponent {
    @Input({ required: true }) text!: string;
    @Input() breadcrumbs!: string[];
    @Input() backBtnRedirect?: string;
}
