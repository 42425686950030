import { CartesianScaleTypeRegistry, ChartConfiguration, Color, ScaleOptionsByType } from 'chart.js';
import { ChartData } from '../models';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';

type ScaleOptions = {
    [key: string]: _DeepPartialObject<ScaleOptionsByType<keyof CartesianScaleTypeRegistry>>;
};

function getVerticalScaleOptions(chartData: ChartData): ScaleOptions {
    return chartData.datasets.reduce((acc, dataset, index) => {
        const yAxisID = `${index}`;
        dataset.yAxisID = yAxisID;
        if (dataset.data.some((item) => typeof item !== 'number')) {
            return acc;
        }
        const [min, max] = [Math.min(...(<number[]>dataset.data)), Math.max(...(<number[]>dataset.data))];
        acc[yAxisID] = min !== max ? { min, max, display: false } : { display: false };
        return acc;
    }, {} as ScaleOptions);
}

export const getChartConfig = (chartData: ChartData): ChartConfiguration<'line'> => {
    const verticalScaleOptions = getVerticalScaleOptions(chartData);
    return {
        type: 'line',
        data: {
            labels: chartData.labels,
            datasets: chartData.datasets.map((dataset) => ({
                borderWidth: 1,
                pointStyle: false,
                tension: 0.4,
                ...dataset,
            })),
        },
        options: {
            interaction: {
                intersect: false,
                mode: 'index',
            },
            layout: {
                autoPadding: false,
            },
            scales: {
                ...verticalScaleOptions,
                x: {
                    display: false,
                    ticks: {
                        display: false,
                    },
                    grid: {
                        drawTicks: false,
                        display: false,
                    },
                },

                y: {
                    min: 0,
                    display: false,
                    ticks: {
                        display: false,
                    },
                    grid: {
                        drawTicks: false,
                        display: false,
                    },
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            plugins: {
                tooltip: {
                    borderWidth: 0,
                    enabled: true,
                    multiKeyBackground: '#00000000',
                    filter: function (tooltipItem) {
                        return tooltipItem.label !== '';
                    },
                    callbacks: {
                        labelColor: (context) => ({
                            borderColor: '#00000000',
                            backgroundColor: <Color>context.dataset.borderColor,
                        }),
                    },
                },
                legend: {
                    display: false,
                },
            },
        },
        plugins: [],
    };
};
