import { createReducer, on } from '@ngrx/store';
import { socialLinksActions } from '../actions/social-links.actions';
import { SocialLinks } from '../models';

export interface SocialLinksState {
    socialLinks: SocialLinks | null;
    loaded: null | boolean;
    loading: boolean;
    saved: boolean | null;
    saving: boolean;
}

export const initialState: SocialLinksState = {
    socialLinks: null,
    loaded: null,
    loading: false,
    saved: null,
    saving: false,
};

export const socialLinksReducer = createReducer(
    initialState,
    on(socialLinksActions.getSocialLinks, (state): SocialLinksState => ({ ...state, loaded: null, loading: true })),
    on(socialLinksActions.getSocialLinksSuccess, (state, { value }): SocialLinksState => ({ ...state, loaded: true, socialLinks: value, loading: false })),
    on(socialLinksActions.getSocialLinksError, (state): SocialLinksState => ({ ...state, loaded: false, loading: false })),

    on(socialLinksActions.editSocialLinks, (state): SocialLinksState => ({ ...state, saved: null, saving: true })),
    on(socialLinksActions.editSocialLinksSuccess, (state, { value }): SocialLinksState => ({ ...state, saved: true, saving: false, socialLinks: value })),
    on(socialLinksActions.editSocialLinksError, (state): SocialLinksState => ({ ...state, saved: false, saving: false })),

    on(socialLinksActions.clearSaved, (state): SocialLinksState => ({ ...state, saved: null })),
    on(socialLinksActions.clear, (): SocialLinksState => ({ ...initialState })),
);
