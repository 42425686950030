<app-card *ngIf="{ timeLeft: timeLeft$ | async } as data" [unstyled]="true">
    <div class="flex gap-6 justify-center px-2 py-6 bg-gradient-gray-40 rounded-2xl">
        <ng-container *ngIf="data.timeLeft !== null">
            <div class="grid justify-items-center">
                <h1>{{ data.timeLeft | daysLeft }}</h1>
                <span class="typo-subtitle2"> {{ 'countdown.days' | translate }} </span>
            </div>

            <div class="grid justify-items-center">
                <h1>{{ data.timeLeft | hoursLeft }}</h1>
                <span class="typo-subtitle2"> {{ 'countdown.hours' | translate }} </span>
            </div>

            <div class="grid justify-items-center">
                <h1>{{ data.timeLeft | minutesLeft }}</h1>
                <span class="typo-subtitle2"> {{ 'countdown.minutes' | translate }} </span>
            </div>
        </ng-container>
    </div>
</app-card>
