<div class="flex gap-4 items-center border-b border-input-placeholder pb-3.5">
    <a
        *ngIf="backBtnRedirect"
        [routerLink]="backBtnRedirect"
        queryParamsHandling="preserve"
        class="size-10 shrink-0 rounded-lg grid place-content-center border border-input-placeholder"
    >
        <app-icon name="chevron-left" class="icon-6 icon-input-placeholder mr-0.5" />
    </a>

    <h1 class="truncate" [title]="text">{{ text }}</h1>

    <div class="ml-auto inline-flex gap-4">
        <ng-content />
    </div>
</div>
