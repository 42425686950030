import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { User, UserDetails, UserDetailsDto, UserDto } from '../models';
import { ApiResponse } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getUser(): Observable<User> {
        // #FIXME - API needs to put user into response.data
        // return this.httpClient.get<ApiResponse<UserDto>>(`api/v1/users/me`).pipe(
        //     map((response) => new User(response.data))
        // );
        return this.httpClient.get<UserDto>(`api/v1/users/me`).pipe(map((response) => new User(response)));
    }

    getUserDetails(): Observable<UserDetails> {
        return this.httpClient.get<ApiResponse<UserDetailsDto>>('api/v1/users/me/details').pipe(map((response) => new UserDetails(response.data)));
    }
}
