<div class="flex gap-2 items-center">
    <a *ngIf="backBtnRedirect" class="size-8 shrink-0 rounded-full border border-input-placeholder grid place-content-center" [routerLink]="backBtnRedirect">
        <app-icon name="chevron-left-thin" class="icon-3 icon-light mr-[1px]" />
    </a>

    <div class="overflow-hidden">
        <ng-container *ngIf="breadcrumbs">
            <p class="typo-overline uppercase">{{ breadcrumbs.join(' / ') }}</p>

            <h2 class="truncate" [title]="text">{{ text }}</h2>
        </ng-container>

        <h1 *ngIf="!breadcrumbs">{{ text }}</h1>
    </div>

    <div class="ml-auto inline-flex gap-4">
        <ng-content />
    </div>
</div>
