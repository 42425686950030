import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ButtonComponent } from '../button/button.component';
import { GrowWidth } from '../../animations';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-button-expand',
    templateUrl: './button-expand.component.html',
    styleUrls: ['./button-expand.component.scss', '../button/button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [GrowWidth],
    standalone: true,
    imports: [CommonModule, ButtonComponent],
})
export class ButtonExpandComponent extends ButtonComponent implements OnInit {
    @Input() expanded: boolean = false;

    expanded$ = new BehaviorSubject(false);

    ngOnInit() {
        this.expanded$.next(this.expanded);
    }

    toggle() {
        this.expanded$.next(!this.expanded$.value);
    }
}
