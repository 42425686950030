<button app-button type="button" [severity]="severity">
    <button type="button" class="aspect-square h-full" (click)="toggle()">
        <ng-content select="[trigger]" />
    </button>

    <div [@GrowWidth]="(expanded$ | async) ? 'open' : 'closed'">
        <div class="whitespace-nowrap pr-3.5">
            <ng-content />
        </div>
    </div>
</button>
