import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitByComma',
    standalone: true,
})
export class SplitByCommaPipe implements PipeTransform {
    transform(value: string): string[] {
        return value.split(',').map((value) => value.trim());
    }
}
