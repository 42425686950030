import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewEncapsulation, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonSeverity, ButtonSize } from '../../types';
import { IconsModule } from '../../../../icons';

@Component({
    selector: '[app-button]',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, IconsModule],
})
export class ButtonComponent implements OnChanges {
    private elementRef: ElementRef<HTMLElement> = inject(ElementRef);
    private renderer = inject(Renderer2);

    @Input() severity: ButtonSeverity = 'primary';
    @Input() size: ButtonSize = 'md';
    @Input() icon?: IconType | (string & NonNullable<unknown>);
    @Input() text?: string;
    @Input() disabled?: boolean = false;
    @Input() truncate: boolean = true;

    ngOnChanges(changes: SimpleChanges) {
        if (changes['disabled'] !== undefined) {
            this.disabled ? this.disableBtn() : this.enableBtn();
        }
    }

    disableBtn() {
        const hostEl = this.elementRef.nativeElement;

        if (hostEl instanceof HTMLButtonElement) {
            this.renderer.setAttribute(hostEl, 'disabled', 'true');
        }

        this.renderer.addClass(hostEl, 'btn-disabled');
        this.renderer.setAttribute(hostEl, 'tabIndex', '-1');
    }

    enableBtn() {
        const hostEl = this.elementRef.nativeElement;

        if (hostEl instanceof HTMLButtonElement) {
            this.renderer.removeAttribute(hostEl, 'disabled');
        }

        this.renderer.removeClass(hostEl, 'btn-disabled');
        this.renderer.removeAttribute(hostEl, 'tabIndex');
    }
}
