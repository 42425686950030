import { CommonModule } from '@angular/common';
import { Component, HostBinding, HostListener } from '@angular/core';
import { Blur } from './backdrop.animation';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-backdrop',
    templateUrl: './backdrop.component.html',
    styleUrls: ['./backdrop.component.scss'],
    standalone: true,
    imports: [CommonModule],
    animations: [Blur],
})
export class BackdropComponent {
    readonly click$ = new Subject<null>();

    @HostBinding('@blur') get(): void {}

    @HostListener('click') click() {
        this.click$.next(null);
    }
}
