import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { getChartConfig } from '../config/chart-config';
import { ChartData } from '../models';

@Component({
    selector: 'app-line-chart',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements AfterViewInit, OnDestroy, OnChanges {
    @ViewChild('lineChart') lineChart!: ElementRef<HTMLCanvasElement>;

    @Input({ required: true }) chartData!: ChartData;

    chart!: Chart;
    isSetHidden: boolean[] = [];
    ngAfterViewInit() {
        this.chart = new Chart(this.lineChart.nativeElement, getChartConfig(this.chartData));
    }

    ngOnChanges(changes: SimpleChanges): void {
        Object.keys(changes).forEach((key) => {
            switch (key) {
                case 'chartData': {
                    if (!this.chart) {
                        return;
                    }

                    const config = getChartConfig(this.chartData);
                    this.chart.data = config.data;
                    this.chart.update();
                }
            }
        });
    }

    ngOnDestroy() {
        this.chart.destroy();
    }

    toggleDataset(index: number) {
        const dataset = this.chart.data.datasets[index];
        dataset.hidden = !dataset.hidden;
        this.isSetHidden[index] = dataset.hidden;
        this.chart.update();
    }
}
