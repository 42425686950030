import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, map, startWith, tap } from 'rxjs';
import { I18nHtmlLangService } from './html-lang.service';
import { Lang } from '../lang.type';
import { UserFacade } from '@app/store/user';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private readonly userFacade = inject(UserFacade);
    private readonly translateService = inject(TranslateService);
    private readonly i18nHtmlLangService = inject(I18nHtmlLangService);

    readonly language$ = this.userFacade.userNonNullable$.pipe(
        map((user) => user.language),
        startWith(environment.defaultLanguage as Lang),
    );

    private subscriptions$ = new Subscription();

    constructor() {
        this.translateService.setDefaultLang(environment.defaultLanguage);

        this.subscriptions$.add(this.language$.pipe(tap((lang) => this.set(lang))).subscribe());
    }

    set(lang: Lang): void {
        this.translateService.use(lang);
        this.i18nHtmlLangService.setLang(lang);
    }
}
