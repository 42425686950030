import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hoursLeft',
    standalone: true,
})
export class HoursLeftPipe implements PipeTransform {
    private readonly DAY_MS = 1000 * 60 * 60 * 24;
    private readonly HOUR_MS = 1000 * 60 * 60;

    transform(value: number): string {
        const hours = this.getHoursLeft(value);

        return String(hours).padStart(2, '0');
    }

    private getHoursLeft(milliseconds: number) {
        return Math.floor((milliseconds % this.DAY_MS) / this.HOUR_MS);
    }
}
