import { Component, OnInit } from '@angular/core';

@Component({
    template: '',
})
export class RefreshSocialsComponent implements OnInit {
    ngOnInit() {
        localStorage.removeItem('refresh-socials');
        localStorage.setItem('refresh-socials', 'true');

        close();
    }
}
