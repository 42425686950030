import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { Lang } from '@app/i18n';
import { selectSaved, selectSaving } from '../selectors/user-management.selector';
import { userManagementActions } from '../actions/user-management.actions';
import { ChangePasswordValue, UserValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class UserManagementFacade {
    private readonly store = inject(Store);

    saved$ = this.store.select(selectSaved);
    saving$ = this.store.select(selectSaving);

    get saving() {
        let saving = false;

        this.saving$
            .pipe(
                take(1),
                tap((value) => (saving = value)),
            )
            .subscribe();

        return saving;
    }

    editUser(value: UserValue): void {
        this.store.dispatch(userManagementActions.editUser({ value }));
    }

    changePassword(value: ChangePasswordValue): void {
        this.store.dispatch(userManagementActions.changePassword({ value }));
    }

    changeLanguage(value: Lang): void {
        this.store.dispatch(userManagementActions.changeLanguage({ value }));
    }

    clearSaved(): void {
        this.store.dispatch(userManagementActions.clearSaved());
    }

    clear(): void {
        this.store.dispatch(userManagementActions.clear());
    }
}
