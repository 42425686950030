import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocialLinks } from '../models';

export const socialLinksActions = createActionGroup({
    source: 'Social Links',
    events: {
        'Get Social Links': emptyProps(),
        'Get Social Links Success': props<{ value: SocialLinks }>(),
        'Get Social Links Error': emptyProps(),

        'Edit Social Links': props<{ value: SocialLinks }>(),
        'Edit Social Links Success': props<{ value: SocialLinks }>(),
        'Edit Social Links Error': emptyProps(),

        'Clear Saved': emptyProps(),
        Clear: emptyProps(),
    },
});
