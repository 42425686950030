import { FileInfoDto } from './file-info.dto';

export class FileInfo {
    id: string;
    completed: boolean;
    completedS3: boolean;
    url: string;
    expire: number;
    fileName: string;

    constructor(dto: FileInfoDto) {
        this.id = dto.fileId;
        this.completed = dto.completed;
        this.completedS3 = dto.completedS3;
        this.url = dto.fileUri;
        this.expire = dto.fileUriExpire;
        this.fileName = dto.fileName ?? '';
    }
}
