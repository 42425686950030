import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, map, take, tap } from 'rxjs';
import { UserFacade } from '../facades/user.facade';

export const userDetailsLoaded: CanActivateFn = () => {
    const userFacade = inject(UserFacade);

    return userFacade.detailsLoaded$.pipe(
        tap((loaded) => {
            if (loaded === null) {
                userFacade.getUserDetails();
            }
        }),
        filter((loaded) => loaded !== null),
        take(1),
        map(() => true),
    );
};
