import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs';
import { userActions } from '../actions/user.actions';
import { User, UserDetails } from '../models';
import { selectDetailsLoaded, selectLoaded, selectUser, selectUserDetails, selectVerificationStatus } from '../selectors/user.selector';

@Injectable({
    providedIn: 'root',
})
export class UserFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    user$ = this.store.select(selectUser);
    userNonNullable$ = this.user$.pipe(filter((data): data is User => data !== null));
    verificationStatus$ = this.store.select(selectVerificationStatus);
    userDetails$ = this.store.select(selectUserDetails);
    userDetailsNonNullable$ = this.store.select(selectUserDetails).pipe(filter((data): data is UserDetails => data !== null));
    detailsLoaded$ = this.store.select(selectDetailsLoaded);

    get user(): User | null {
        let user = null;

        this.user$
            .pipe(
                take(1),
                tap((value) => (user = value)),
            )
            .subscribe();

        return user;
    }

    getUser(): void {
        this.store.dispatch(userActions.getUser());
    }

    getUserDetails(): void {
        this.store.dispatch(userActions.getUserDetails());
    }

    clear(): void {
        this.store.dispatch(userActions.clear());
    }
}
