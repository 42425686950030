import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@app/shared/icons';

@Component({
    selector: 'app-card',
    standalone: true,
    imports: [CommonModule, IconsModule],
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
    @Input() title?: string;
    @Input() titleIcon?: IconType;
    @Input() titleSize?: 'h2' | 'h3' = 'h2';
    @Input() unstyled = false;
    @Input() radius?: 'md' | 'lg' = 'lg';
}
