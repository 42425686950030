import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { OpenCloseSpinner } from '../../animations/spinner.animation';
import { LoaderComponent } from '../loader/loader.component';

@Component({
    selector: 'app-fullscreen-loader',
    templateUrl: './fullscreen-loader.component.html',
    styleUrls: ['./fullscreen-loader.component.scss'],
    standalone: true,
    imports: [CommonModule, LoaderComponent],
    animations: [OpenCloseSpinner],
})
export class FullscreenLoaderComponent {
    @HostBinding('@openCloseSpinner')
    get(): void {}
}
