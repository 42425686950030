import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../reducers/user.reducer';
import { USER_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

export const selectLoaded = createSelector(selectState, (state: UserState) => state.loaded);
export const selectUser = createSelector(selectState, (state: UserState) => state.user);
export const selectUserDetails = createSelector(selectState, (state: UserState) => state.userDetails);
export const selectDetailsLoaded = createSelector(selectState, (state: UserState) => state.detailsLoaded);
export const selectVerificationStatus = createSelector(selectState, (state: UserState) => state.user?.verificationStatus);
