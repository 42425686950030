import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { selectLoaded, selectLoading, selectSaved, selectSaving, selectSocialLinks } from '../selectors/social-links.selector';
import { socialLinksActions } from '../actions/social-links.actions';
import { SocialLinks } from '../models';

@Injectable({
    providedIn: 'root',
})
export class SocialLinksFacade {
    private readonly store = inject(Store);

    socialLinks$ = this.store.select(selectSocialLinks).pipe(filter((socialLinks): socialLinks is SocialLinks => socialLinks !== null));
    loaded$ = this.store.select(selectLoaded);
    loading$ = this.store.select(selectLoading);
    saved$ = this.store.select(selectSaved);
    saving$ = this.store.select(selectSaving);

    get saving() {
        let saving = false;

        this.saving$
            .pipe(
                take(1),
                tap((value) => (saving = value)),
            )
            .subscribe();

        return saving;
    }

    getSocialLinks(): void {
        this.store.dispatch(socialLinksActions.getSocialLinks());
    }

    editSocialLinks(value: SocialLinks): void {
        this.store.dispatch(socialLinksActions.editSocialLinks({ value }));
    }

    clearSaved(): void {
        this.store.dispatch(socialLinksActions.clearSaved());
    }

    clear(): void {
        this.store.dispatch(socialLinksActions.clear());
    }
}
