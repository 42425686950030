import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User, UserDetails } from '../models';

export const userActions = createActionGroup({
    source: 'User',
    events: {
        'Get User': emptyProps(),
        'Get User Success': props<{ user: User }>(),
        'Get User Error': emptyProps(),

        'Get User Details': emptyProps(),
        'Get User Details Success': props<{ userDetails: UserDetails }>(),
        'Get User Details Error': emptyProps(),

        Clear: emptyProps(),
    },
});
