import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minutesLeft',
    standalone: true,
})
export class MinutesLeftPipe implements PipeTransform {
    private readonly HOUR_MS = 1000 * 60 * 60;
    private readonly MINUTE_MS = 1000 * 60;

    transform(value: number): string {
        const minutes = this.getMinutesLeft(value);

        return String(minutes).padStart(2, '0');
    }

    private getMinutesLeft(milliseconds: number) {
        return Math.floor((milliseconds % this.HOUR_MS) / this.MINUTE_MS);
    }
}
