import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'daysLeft',
    standalone: true,
})
export class DaysLeftPipe implements PipeTransform {
    private readonly DAY_MS = 1000 * 60 * 60 * 24;

    transform(value: number): string {
        const days = this.getDaysLeft(value);

        return String(days).padStart(2, '0');
    }

    private getDaysLeft(milliseconds: number) {
        return Math.floor(milliseconds / this.DAY_MS);
    }
}
