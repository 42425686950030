import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocialLinksState } from '../reducers/social-links.reducer';
import { SOCIAL_LINKS_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<SocialLinksState>(SOCIAL_LINKS_FEATURE_KEY);

export const selectSocialLinks = createSelector(selectState, (state: SocialLinksState) => state.socialLinks);
export const selectLoaded = createSelector(selectState, (state: SocialLinksState) => state.loaded);
export const selectLoading = createSelector(selectState, (state: SocialLinksState) => state.loading);
export const selectSaved = createSelector(selectState, (state: SocialLinksState) => state.saved);
export const selectSaving = createSelector(selectState, (state: SocialLinksState) => state.saving);
