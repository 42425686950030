<div class="h-full grid grid-rows-[auto_minmax(0,1fr)_auto]">
    <div class="inline-flex flex-wrap gap-4 items-center cursor-default mb-3.5">
        <button
            *ngFor="let item of chartData.datasets; let i = index"
            class="inline-flex items-center gap-2 cursor-pointer"
            tabindex="1"
            (keydown)="toggleDataset(i)"
            (click)="toggleDataset(i)"
        >
            <div class="w-3 h-[1px]" style="background-color: {{ item.borderColor }};"></div>
            <span class="uppercase typo-overline" [ngClass]="isSetHidden[i] ? 'line-through' : ''">{{ item.label }}</span>
        </button>
    </div>

    <div class="w-full h-full pr-2 pt-2">
        <div class="chart-container">
            <canvas #lineChart class="!w-full !h-full"></canvas>
        </div>
    </div>

    <div class="w-full text-right uppercase mt-2.5 typo-overline">{{ chartData.title }}</div>
</div>
