<div class="steps">
    <ng-container *ngFor="let step of steps; let i = index; let last = last">
        @if (step.enabled) {
            <a class="step text-light" [routerLink]="step.fullPath" queryParamsHandling="preserve" [class.step-active]="i === (activeStepIndex$ | async)">
                <div>{{ i + 1 }}</div>

                <span>{{ step.translationKey | translate }}</span>
            </a>
        } @else {
            <div class="step text-gray-40-light" [class.step-active]="i === (activeStepIndex$ | async)">
                <div>{{ i + 1 }}</div>

                <span>{{ step.translationKey | translate }}</span>
            </div>
        }
    </ng-container>
</div>
