import { CompanyDataDto, IndividualDataDto } from './contractor-data.dto';

class CompanyData {
    type = 'company' as const;
    city: string;
    zipCode: string;
    street: string;
    streetNumber: string;
    apartmentNumber: string;
    companyName: string;
    vatin: string;

    constructor(dto: CompanyDataDto) {
        this.city = dto.city;
        this.zipCode = dto.zipCode;
        this.street = dto.street;
        this.streetNumber = dto.streetNumber;
        this.apartmentNumber = dto.apartmentNumber;
        this.companyName = dto.companyName;
        this.vatin = dto.vatin;
    }
}

class IndividualData {
    type = 'individual' as const;
    city: string;
    zipCode: string;
    street: string;
    streetNumber: string;
    nin: string;
    taxOfficeAddress: string;

    constructor(dto: IndividualDataDto) {
        this.city = dto.city;
        this.zipCode = dto.zipCode;
        this.street = dto.street;
        this.streetNumber = dto.streetNumber;
        this.nin = dto.nin;
        this.taxOfficeAddress = dto.taxOfficeAddress;
    }
}

export { CompanyData, IndividualData };
