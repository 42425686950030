import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SOCIAL_LINKS_FEATURE_KEY } from './keys';
import { socialLinksReducer } from './reducers/social-links.reducer';
import { SocialLinksEffects } from './effects/social-links.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(SOCIAL_LINKS_FEATURE_KEY, socialLinksReducer), EffectsModule.forFeature([SocialLinksEffects])],
})
export class SocialLinksStoreModule {}
