<div class="steps">
    <ng-container *ngFor="let step of steps; let i = index; let last = last">
        @if (step.enabled) {
            <a class="step" [routerLink]="step.fullPath" queryParamsHandling="preserve" [class.step-active]="i === (activeStepIndex$ | async)">{{ i + 1 }} </a>
        } @else {
            <span class="step text-gray-40-light" [class.step-active]="i === (activeStepIndex$ | async)">
                {{ i + 1 }}
            </span>
        }

        <div *ngIf="!last" class="separator"></div>
    </ng-container>
</div>
