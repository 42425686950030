import { Injectable, inject } from '@angular/core';
import { of, catchError, map, mergeMap, retry } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mediaActions } from '../actions/media.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class MediaEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    getFile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(mediaActions.getFile),
            mergeMap(({ id }) => {
                return this.httpService.getFile(id).pipe(
                    retry({ count: 3, delay: 1000 }),
                    map((fileInfo) => {
                        return mediaActions.getFileSuccess({ id, fileInfo });
                    }),
                    catchError(() => of(mediaActions.getFileError({ id }))),
                );
            }, 3),
        );
    });
}
