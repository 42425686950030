import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, of } from 'rxjs';
import { socialLinksActions } from '../actions/social-links.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class SocialLinksEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    getSocialLinks$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(socialLinksActions.getSocialLinks),
            exhaustMap(() => {
                return this.httpService.getSocialLinks().pipe(
                    map((value) => socialLinksActions.getSocialLinksSuccess({ value })),
                    catchError(() => of(socialLinksActions.getSocialLinksError())),
                );
            }),
        );
    });

    editSocialLinks$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(socialLinksActions.editSocialLinks),
            mergeMap(({ value }) => {
                return this.httpService.editSocialLinks(value).pipe(
                    map(() => socialLinksActions.editSocialLinksSuccess({ value })),
                    catchError(() => of(socialLinksActions.editSocialLinksError())),
                );
            }),
        );
    });
}
