{
  "request-error": "An error occurred while processing the request",
  "contractor-type.individual": "Individual",
  "contractor-type.company": "Company",
  "countdown": {
    "days": "days",
    "hours": "hours",
    "minutes": "minutes"
  },
  "status": {
    "pending": "Pending verification",
    "accepted": "Accepted",
    "rejected": "Rejected"
  },
  "no_data": "No data",
  "percentage_grow": "(+{{percentageDifference}}%)",
  "percentage_loss": "({{percentageDifference}}%)",
  "social_media_links": {
    "youtubeLink": "YouTube",
    "facebookLink": "Facebook",
    "spotifyLink": "Spotify",
    "instagramLink": "Instagram",
    "tiktokLink": "TikTok"
  },
  "social_media": {
    "youtube": "YouTube",
    "facebook": "Facebook",
    "spotify": "Spotify",
    "instagram": "Instagram",
    "googleCalendar": "Google Calendar",
    "googleMail": "Google Mail",
    "tiktok": "TikTok",
    "twitter": "Twitter/X"
  },
  "yes": "Yes",
  "no": "No",
  "save": "Save",
  "or": "or",
  "next": "Next",
  "skip": "Skip",
  "show": "Show",
  "new": "New",
  "hide": "Hide",
  "change": "Change",
  "remove": "Remove",
  "title": "ADM",
  "cancel": "Cancel",
  "auth": {
    "labels": {
      "account_type": "Account type",
      "email": "Email",
      "password": "Password",
      "repeat_password": "Repeat new password",
      "contractor_type": "Contractor type"
    },
    "placeholders": {
      "email": "Enter email address",
      "password": "Enter password",
      "repeat_password": "Enter password"
    },
    "regulations": "I have read and accept the <a href=\"{{ regulations }} \" target=\"_blank\">terms and conditions</a> as well as the <a href=\"{{ privacyPolicy }}\" target=\"_blank\">privacy policy</a> and I accept the creation of an ADM account for the provided email address",
    "create_account": "Create account",
    "register_helptext": "Don't have an account yet?",
    "register": "Register",
    "sign_in_helptext": "Already have an account?",
    "sign_in": "Sign in",
    "remind_password": "Remind password",
    "reset_password": "Reset password",
    "remember_password": "Remember password?",
    "change_password": "Change password"
  },
  "profile_setup": {
    "welcome": {
      "text1": "To fully utilize ADM features, we need to verify your account. To do this, you need to complete your profile.",
      "text2": "You can do this later, once you familiarize yourself with our capabilities.",
      "text3": "The decision is yours :)"
    },
    "complete_setup": "Complete setup",
    "complete_profile": "Complete your profile",
    "verify_profile": "Verify your profile",
    "welcome_msg": "Welcome to ADM",
    "form": {
      "title": "Your details",
      "upload_image": "Upload photo",
      "upload_info": "Photos in JPG, PNG format in 1:1 ratio",
      "labels": {
        "avatar": "Avatar",
        "first_name": "First name",
        "last_name": "Last name",
        "pseudonym": "Pseudonym",
        "phone_number": "Phone number",
        "contractor_type": "Contractor type",
        "city": "City",
        "zipcode": "Zip code",
        "street": "Street",
        "street_number": "Street number",
        "apartment_number": "Apartment number",
        "company_name": "Company name",
        "vatin": "VATIN",
        "tax_office_address": "Tax office address",
        "nin": "PESEL"
      },
      "placeholders": {
        "first_name": "Enter first name",
        "last_name": "Enter last name",
        "pseudonym": "Enter pseudonym",
        "phone_number": "Enter phone number",
        "nip": "Enter NIP",
        "city": "Enter city",
        "zipcode": "Enter zip code",
        "street": "Enter street",
        "street_number": "Enter street number",
        "apartment_number": "Enter apartment number",
        "company_name": "Enter company name",
        "vatin": "Enter VATIN",
        "tax_office_address": "Enter tax office address",
        "nin": "Enter PESEL"
      },
      "helptext": {
        "phone_number": "9 digits, without spaces and special characters",
        "nip": "10 digits, without spaces and special characters"
      }
    },
    "socials": {
      "title": "Social media",
      "social_link": "Enter URL address"
    }
  },
  "account_type": {
    "artist": "Artist",
    "manager": "Manager"
  },
  "forms": {
    "error": {
      "message": {
        "date_range": "Invalid date range",
        "required": "This field is required",
        "postal_code": "Invalid postal code",
        "invalid_address": "Provide city name, street, and house number",
        "invalid_street": "Provide street name and house number",
        "invalid_street_number": "Provide house number",
        "new_password": "New password must be the same in both fields",
        "max_length": "Maximum number of characters exceeded",
        "min_length": "Minimum number of characters: {{min_length}}",
        "max": "Maximum value: {{max}}",
        "invalid_url": "Invalid URL address",
        "general": "Invalid field value",
        "at-least-upper": "Minimum number of uppercase letters: {{required_length}}",
        "at-least-lower": "Minimum number of lowercase letters: {{required_length}}",
        "at-least-numbers": "Minimum number of digits: {{required_length}}",
        "at-least-symbols": "Minimum number of special characters: {{required_length}}\nAllowed symbols: #?!@$%^&*-",
        "words": "Field should contain only letters",
        "at_least_one_capital": "Field should contain at least one capital letter",
        "at_least_one_lower": "Field should contain at least one lowercase letter",
        "at_least_one_number": "Field should contain at least one digit",
        "at_least_one_symbol": "Field should contain at least one symbol - #?!@$%^&*-",
        "at_least_one_required": "At least one field must be filled",
        "file_type": "The file contains an invalid format, please upload the file in {{ name }} format",
        "file_type_wav": "The song contains an invalid format, please upload the song in {{ name }} format",
        "file_size": "The file is too small, please upload a file with the minimum size of {{ size }}",
        "max_file_size": "File is too large, please upload a file with a maximum size of {{ size }}",
        "file_size_wav": "The song is of too low quality, please upload a file with a minimum size of {{ size }}",
        "file_size_empty": "The file contains no content",
        "image_resolution": "The cover is too low quality, the uploaded file must contain a minimum size of {{ x }}px x {{ y }}px"
      }
    }
  },
  "distribution": {
    "save_draft": {
      "title": "Leaving the form",
      "content": "Do you want to save the data in the form for later?"
    },
    "title": "Distribution",
    "single": "Single",
    "sign_annex": "To fully utilize this panel's capabilities, sign the annex digitally",
    "subpages": {
      "digital": "Digital",
      "physical": "Physical release",
      "catalog": "Catalog"
    },
    "buttons": {
      "digital_description": "Plan single release",
      "physical_description": "Release CD/Vinyl album",
      "catalog_description": "View catalog"
    },
    "catalogue": {
      "title": "Catalog",
      "create_album": "Release album",
      "single_planning": "Plan single release",
      "type": {
        "single": "Single",
        "album": "Album"
      },
      "radio_select": {
        "all": "All",
        "published": "Published",
        "planned": "Planned"
      },
      "published": "Published",
      "planned": "Planned",
      "empty": "You don't have any albums in the catalog at the moment.",
      "empty_published": "You don't have any albums released at the moment",
      "empty_planned": "You don't have any albums planned at this time"
    },
    "catalog_details": {
      "actions": "Actions",
      "promo": "Promote",
      "order_merch": "Order merch",
      "order_reissue": "Order reissue",
      "countdown_label": "Time left until premiere",
      "create_marketing_plan": "Create marketing plan",
      "create_marketing_plan_label": "This release does not have a created marketing plan.",
      "pending_verification_single_label": "This single has not yet been approved.",
      "about": {
        "single": "About single",
        "album": "About album"
      }
    },
    "planning": {
      "sign_contract_info": "To place an order, signing a contract with ADM is required",
      "sign_contract": "Sign contract",
      "distribution": "Distribution",
      "digital": "Digital",
      "title": "Plan single premiere",
      "desktop_first": "Information and materials",
      "desktop_second": "Summary",
      "step_basic_info": "Basic information",
      "step_additional_info": "Additional information",
      "step_assets": "Materials",
      "step_summary": "Summary",
      "form": {
        "invalid_producers_count": "Invalid producers count",
        "labels": {
          "author": "Recording artist(s)",
          "title": "Recording title",
          "description": "Description",
          "producer": "Producer",
          "producer_details": "Producer (first and last name)",
          "lyrics_author": "Lyricists (first and last name)",
          "label": "Label",
          "release_year": "Release year",
          "publication_date": "Publication date",
          "tiktok_fragment": "TikTok start fragment",
          "swear_words": "Presence of swear words",
          "cover_file": "Cover",
          "audio_file": "Audio file",
          "lyrics_file": "Lyrics",
          "short_video_file": "Video for spotify/tidal"
        },
        "placeholders": {
          "author": "Artist",
          "title": "Title",
          "description": "Description",
          "producer": "Producer",
          "producer_details": "Producer (first and last name)",
          "lyrics_author": "Lyricists",
          "label": "Label",
          "release_year": "2024",
          "publication_date": "2024-09-21",
          "tiktok_fragment": "TikTok start fragment",
          "cover_file": "Upload cover",
          "audio_file": "Upload WAV file",
          "lyrics_file": "Upload txt/doc file",
          "short_video_file": "Upload video file"
        },
        "descriptions": {
          "cover_file": "Photos in PNG format with dimensions 2400x2400 PX"
        },
        "helptext": {
          "tiktok_fragment": "Required format mm:ss (e.g., 0:22, 00:22)",
          "comma_separated": "If there is more than 1, they should be separated by commas"
        }
      },
      "summary": {
        "author": "Artist(s)",
        "title": "Title",
        "producer": "Producer",
        "lyrics_author": "Lyricist",
        "description": "Description",
        "label": "Label",
        "release_year": "Release year",
        "publication_date": "Publication date",
        "duration": "Duration",
        "tiktok_fragment": "TikTok start fragment",
        "swear_words": "Presence of swear words"
      },
      "processing": {
        "processing_title": "Processing",
        "confirmation_title": "Confirmation",
        "waiting_for_verification": "Your submission is awaiting verification by the administrator.",
        "verification_time": "Verification may take up to 48 hours",
        "whats_next": "What's next?",
        "whats_next_content": "Use our planner and create a marketing plan tailored to Spotify / Tidal",
        "create_marketing_plan": "Create marketing\nplan",
        "see_catalog": "See catalog",
        "tooltip": "Functionality will be available soon"
      },
      "loader": {
        "processing": "Your submission is being processed",
        "wait": "Please wait for confirmation"
      }
    },
    "drafts_listing": {
      "title": "Saved forms",
      "saved_drafts": "Your saved forms:"
    }
  },
  "navigation": {
    "dashboard": "Dashboard",
    "distribution": "Distribution",
    "analytics": "Analytics",
    "analytics_full": "Analytics and Finance",
    "marketing": "Marketing",
    "ecommerce": "E-commerce",
    "booking": "Booking",
    "education": "ADM Education",
    "claims": "File Claim",
    "contact": "Contact Us"
  },
  "sidebar": {
    "distribution": {
      "release_album": "Release Album CD/vinyl",
      "plan_premiere": "Plan Premiere Single",
      "catalog": "Catalog"
    },
    "analytics": {
      "finance": "Finance",
      "marketing": "Marketing"
    },
    "marketing": {
      "plans": "Marketing Plans",
      "planner": "Planner",
      "paid_ads": "Order Paid Ads",
      "press_note": "Press Note",
      "send_single": "Send Single"
    },
    "ecommerce": {
      "order_merch": "Order Merch",
      "report_preorder": "Report Preorder",
      "add_own_products": "Add Own Products",
      "order_landing_page": "Order Landing Page",
      "catalog": "Catalog"
    },
    "education": {
      "adm_blog": "ADM Blog",
      "adm_e_learning": "ADM E-Learning"
    }
  },
  "marketing": {
    "title": "Marketing",
    "buttons": {
      "category_spotify_tidal": "Spotify / Tidal",
      "category_social_media": "Social Media",
      "category_pr": "PR",
      "category_media": "Media",
      "label_plans": "Marketing Plans",
      "label_planer": "Planner",
      "label_order_paid_ads": "Order Paid Ads",
      "label_press_note": "Press Note",
      "label_send_single": "Send Single"
    }
  },
  "ecommerce": {
    "title": "E-commerce",
    "buttons": {
      "category_magazine": "Magazine",
      "category_shop": "Shop",
      "category_service": "Service",
      "category_your_magazine": "Your Magazine",
      "category_support": "Support",
      "label_catalog": "Catalog",
      "label_order_merch": "Order Merch",
      "label_report_preorder": "Report Preorder",
      "label_add_own_products": "Add Own Products",
      "label_order_landing_page": "Order Landing Page"
    }
  },
  "booking": {
    "title": "Booking",
    "buttons": {
      "category_offer": "Offer",
      "category_concerts": "Concerts",
      "category_sales": "Sales",
      "category_support": "Support",
      "label_offer": "Booking Offer",
      "label_calendar": "Calendar",
      "label_tixy": "Tixy",
      "label_order_manager": "Order Manager 360"
    }
  },
  "hi": "Hi",
  "hi_name": "Hi {{ name }}",
  "dashboard": {
    "intro_empty": "Here you will find the latest data regarding your releases, tasks, and statistics.",
    "buttons": {
      "release_album": "Release Album",
      "link_accounts": "Link Accounts"
    },
    "tabs": {
      "newest": "Newest",
      "statistics": "Statistics",
      "shortcuts": "Shortcuts"
    },
    "intro_no_statistics_1": "Oh no, your statistics are missing here! Connect your YouTube, Spotify, Google, and social media profiles in the",
    "intro_no_statistics_link": "user profile tab ",
    "intro_no_statistics_2": "to access full analytics of your career!"
  },
  "user": {
    "details": {
      "name_and_surname": "Name and Surname",
      "pseudonym": "Pseudonym",
      "account_type": "Account Type",
      "email": "Email Address",
      "telephone": "Telephone",
      "contractor_type": "Contractor type",
      "city": "City",
      "zipcode": "Zip code",
      "street": "Street",
      "street_number": "Street number",
      "apartment_number": "Apartment number",
      "company_name": "Company name",
      "vatin": "VATIN",
      "tax_office_address": "Tax office address",
      "nin": "PESEL"
    },
    "edit": {
      "title": "Edit Profile",
      "success": "User data has been saved",
      "error": "Failed to save user data",
      "invalid-pesel": "Invalid PESEL",
      "invalid-vatin": "Invalid VATIN"
    }
  },
  "buttons": {
    "edit_data": "Edit Data"
  },
  "header": {
    "calendar": "Calendar",
    "news": "News",
    "business": "My Business",
    "popover_menu": {
      "my_profile": "My Profile",
      "logout": "Logout"
    }
  },
  "language": "Language",
  "profile": {
    "title": "Profile",
    "socials": {
      "title": "Connected Profiles"
    }
  },
  "languages": {
    "pl-PL": "PL",
    "en-EN": "EN"
  },
  "user_avatar": {
    "edit": {
      "success": "Avatar has been saved",
      "error": "Failed to save avatar"
    },
    "delete": {
      "success": "Avatar has been deleted"
    }
  },
  "my_business": "My Business",
  "notifications": "Notifications",
  "messages": "Messages",
  "file_claim": "File Claim",
  "education": "ADM Education",
  "adm_blog": "ADM Blog",
  "adm_e_learning": "ADM E-Learning",
  "contact_us": "Contact Us",
  "logout": "Logout",
  "menu": "Menu",
  "analytics": {
    "title": "Analytics",
    "marketing": "Marketing",
    "finance": "Finance",
    "send-invoice": "Send Invoice",
    "conversion": "Conversion",
    "day": "Day",
    "month": "Month",
    "income": "Income",
    "views": "Views",
    "google_analytics_report": "WWW (Google Analytics Data)",
    "report": {
      "not_available": "Report is currently unavailable",
      "all-time": "All Time",
      "youtube": "YouTube",
      "spotify": "Spotify",
      "otherPlatforms": "Other Streaming Platforms",
      "concerts": "Concerts",
      "tixy": "Tixy",
      "mediaSales": "CD/Vinyl Sales",
      "merchSales": "Merch",
      "facebook": "Facebook",
      "instagram": "Instagram",
      "instagramReels": "Instagram Reels",
      "tiktok": "TikTok",
      "overall": "Overall",
      "googleAnalysticsUsers": "Number of Users",
      "googleAnalysticsConversions": "Number of Conversions",
      "googleAnalysticsSessionDuration": "Session Duration"
    },
    "date-range": {
      "last-month": "This Month",
      "all-time": "All Time"
    },
    "report-types": {
      "finance": "Finance",
      "marketing": "Marketing"
    }
  },
  "uploader": {
    "in-queue": "Files in Queue: {{ count }}",
    "uploading": "Uploading file:",
    "progress": "{{ percent }}%",
    "preparing": "Preparing..."
  },
  "calendar": {
    "title": "Calendar",
    "btn": {
      "add": "Add"
    },
    "tag": {
      "sale": "Sale",
      "releases": "Releases",
      "concerts": "Concerts",
      "other": "Other"
    },
    "weekday": {
      "0": "Mon",
      "1": "Tue",
      "2": "Wed",
      "3": "Thu",
      "4": "Fri",
      "5": "Sat",
      "6": "Sun"
    },
    "month": {
      "0": "January",
      "1": "February",
      "2": "March",
      "3": "April",
      "4": "May",
      "5": "June",
      "6": "July",
      "7": "August",
      "8": "September",
      "9": "October",
      "10": "November",
      "11": "December"
    },
    "placeholder": {
      "input": "Select Date"
    },
    "event": {
      "title": "New event",
      "event_type": "Event type",
      "tabs": {
        "sale": "Sale",
        "releases": "Release",
        "concert": "Concert"
      },
      "form": {
        "cancel": "Cancel",
        "save": "Save",
        "concert": {
          "date": "Date",
          "place": "Place",
          "description": "Description"
        },
        "release": {
          "date": "Date",
          "title": "Title",
          "description": "Description"
        },
        "sale": {
          "publish_date": "Publish Date",
          "task_name": "Task Name",
          "connected_release": "Connected Release",
          "connected_release_placeholder": "Select Option",
          "post_text": "Post Text",
          "upload_img_video": "Upload Image/Video",
          "add_channel": "Add Other Channels",
          "facebook": "Facebook Feed",
          "youtube": "YouTube"
        }
      }
    },
    "list": {
      "select_day": "Select a date to see events scheduled for that day",
      "empty_list": "No events scheduled for the selected date"
    }
  },
  "chat": {
    "title": "Messages",
    "list": {
      "new_chat": "New message",
      "no_messages": "No messages"
    },
    "placeholder": {
      "input": "Type something..."
    },
    "button": {
      "send": "Send"
    },
    "try_again": "Try Again"
  },
  "my_profile": "My Profile",
  "logout_profile": "Logout",
  "loading": "Loading",
  "selector": {
    "no_data": "No options"
  },
  "pending_verification": {
    "title": "Hi {{userName}}",
    "verification_in_progress": "Your account verification is in progress.",
    "verification_info": "Verification takes up to 48 hours. After completion, you will receive information to the provided email address."
  },
  "other_info": {
    "not_available": "These features will be available soon"
  },
  "cl": {
    "account": {
      "login-success": "Logged in successfully",
      "login-invalid-credentials": "Invalid password or email address",
      "account_not_active": "Account is not active",
      "account-blocked": "The account has been blocked, please contact ADM support",
      "login-not-activated": "Account is not activated",
      "logout-success": "Logged out successfully",
      "logout-error": "Error occurred while logging out",
      "register-error": "An error occurred while processing the request",
      "reset-password-request-success": "Password reset email has been sent",
      "reset-password-request-error": "An error occurred while processing the request",
      "reset-password-invalid-hash-error": "The link has expired or is invalid",
      "reset-password-success": "Password has been updated",
      "reset-password-error": "An error occurred while processing the request",
      "activate-success": "Account has been activated",
      "activate-error": "An error occurred while activating the account",
      "resend-activation-link-success": "Activation link has been sent",
      "resend-activation-link-error": "An error occurred while processing the request",
      "account-link-success": "Profile has been connected",
      "account-link-error": "Error while connecting the profile"
    }
  }
}
