import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    template: '',
    selector: 'app-separator',
    styleUrls: ['./separator.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeparatorComponent {
    @Input() type: 'row' | 'col' = 'row';
    @Input() color: 'light' | 'dark' = 'light';

    @HostBinding('class') get separator() {
        return `separator-${this.type}-${this.color}`;
    }
}
