import { createReducer, on } from '@ngrx/store';
import { mediaActions } from '../actions/media.actions';
import { FileInfo } from '../models/file-info';

export interface MediaState {
    loaded: Map<string, boolean | null>;
    files: Map<string, FileInfo>;
}

export const initialState: MediaState = {
    loaded: new Map(),
    files: new Map(),
};

export const mediaReducer = createReducer(
    initialState,
    on(mediaActions.getFile, (state, payload): MediaState => {
        const loaded = new Map(state.loaded);

        loaded.set(payload.id, null);

        return {
            ...state,
            loaded,
        };
    }),

    on(mediaActions.getFileSuccess, (state, payload): MediaState => {
        const images = new Map(state.files);
        const loaded = new Map(state.loaded);

        images.set(payload.id, payload.fileInfo);
        loaded.set(payload.id, true);

        return {
            ...state,
            files: images,
            loaded,
        };
    }),

    on(mediaActions.getFileError, (state, payload): MediaState => {
        const loaded = new Map(state.loaded);

        loaded.set(payload.id, false);

        return {
            ...state,
            loaded,
        };
    }),

    on(
        mediaActions.clear,
        (): MediaState => ({
            ...initialState,
        }),
    ),
);
