import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { filter, map, startWith } from 'rxjs';
import { DesktopStep, StepWithLink } from '../models/desktop-step';

@Component({
    selector: 'app-desktop-stepper',
    templateUrl: './desktop-stepper.component.html',
    styleUrls: ['./desktop-stepper.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, TranslateModule],
})
export class DesktopStepperComponent {
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);

    @Input({ required: true })
    get steps(): StepWithLink[] {
        return this._steps;
    }
    set steps(value: DesktopStep[]) {
        this._steps = value.map((value) => ({ ...value, fullPath: this.createLink(value.path) }));
    }
    private _steps: StepWithLink[] = [];

    readonly activeStepIndex$ = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router),
        map(() => {
            const currentStepPath = this.getStepRoute().routeConfig?.path;

            return this.steps.findIndex((step) => (currentStepPath ? step.matchingMobilePaths.includes(currentStepPath) : false));
        }),
    );

    private getStepRoute() {
        return this.activatedRoute.firstChild ?? this.activatedRoute;
    }

    private createLink(path: string) {
        return this.router.createUrlTree([path], { relativeTo: this.getStepRoute().parent }).toString();
    }
}
