import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MediaState } from '../reducers/media.reducer';
import { MEDIA_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<MediaState>(MEDIA_FEATURE_KEY);
const selectFiles = createSelector(selectState, (state) => state.files);

const selectLoaded = (id: string) => createSelector(selectState, (state) => state.loaded.get(id) ?? null);
const selectFile = (id: string) => createSelector(selectState, (state) => state.files.get(id) ?? null);
const selectFileUrl = (id: string) => createSelector(selectFile(id), (file) => file?.url ?? null);

export { selectFiles, selectFile, selectFileUrl, selectLoaded };
