import { SingleDraftDto } from './single-draft.dto';

export class SingleDraft {
    id: string;
    artists: string;
    name: string;
    description: string;
    producers: string;
    producersFullNames: string;
    textAuthors: string;
    label: string;
    releaseYear: number;
    publishDate: Date | null;
    parentalAdvisory: boolean;
    tiktokFragment: string;
    coverFileId: string | null;
    audioFileId: string | null;
    lyricsFileId: string | null;
    shortVideoFileId: string | null;

    constructor(dto: SingleDraftDto) {
        this.id = dto.id;
        this.artists = dto.artists ?? '';
        this.name = dto.name ?? '';
        this.description = dto.description ?? '';
        this.producers = dto.producers ?? '';
        this.producersFullNames = dto.producersFullNames ?? '';
        this.textAuthors = dto.textAuthors ?? '';
        this.label = dto.label ?? '';
        this.releaseYear = dto.releaseYear ?? new Date().getFullYear();
        this.publishDate = this.getPublishDate(dto.publishDate);
        this.tiktokFragment = dto.tiktokFragment ?? '';
        this.parentalAdvisory = dto.parentalAdvisory ?? false;
        this.coverFileId = dto.coverFile ?? null;
        this.audioFileId = dto.audioFile ?? null;
        this.lyricsFileId = dto.lyricsFile ?? null;
        this.shortVideoFileId = dto.shortVideoFile ?? null;
    }

    private getPublishDate(value: number | undefined) {
        const date = value ? new Date(Math.round(value * 1000)) : null;

        if (date === null || date.getTime() < new Date().getTime()) {
            return null;
        }

        return date;
    }
}
