import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IndicatorSize } from '../types/indicator-size';

@Component({
    selector: 'app-indicator',
    templateUrl: './indicator.component.html',
    styleUrls: ['./indicator.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class IndicatorComponent {
    @Input() size: IndicatorSize = 'md';
    @Input({ required: true }) amount!: number;
}
