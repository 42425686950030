import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Status } from '../types/status.type';

@Component({
    selector: 'app-status',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss'],
})
export class StatusComponent {
    @Input() status: Status = 'pending';
    @Input() text?: string;
}
