import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { UserFacade } from '../facades/user.facade';
import { map } from 'rxjs';

export const verificationStatusGuard: CanActivateChildFn = (route, routerSnapshot) => {
    const userFacade = inject(UserFacade);
    const router = inject(Router);

    return userFacade.verificationStatus$.pipe(
        map((status) => {
            switch (status) {
                case 'unverified':
                    return routerSnapshot.url.startsWith('/setup') ? true : router.createUrlTree(['/setup']);

                case 'pending':
                    return routerSnapshot.url === '/verification/pending' ? true : router.createUrlTree(['/verification/pending']);

                case 'verified':
                default:
                    return routerSnapshot.url.startsWith('/setup') || routerSnapshot.url === '/verification/pending' ? router.createUrlTree(['/']) : true;
            }
        }),
    );
};
