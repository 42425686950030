import { Injectable, inject } from '@angular/core';
import { asapScheduler } from 'rxjs';
import { Store } from '@ngrx/store';
import { mediaActions } from '../actions/media.actions';
import { selectFile, selectFileUrl, selectLoaded } from '../selectors/media.selector';

@Injectable({
    providedIn: 'root',
})
export class MediaFacade {
    private readonly store = inject(Store);

    file$ = (id: string) => this.store.select(selectFile(id));
    fileUrl$ = (id: string) => this.store.select(selectFileUrl(id));
    imageLoaded$ = (id: string) => this.store.select(selectLoaded(id));

    getFile(id: string): void {
        asapScheduler.schedule(() => this.store.dispatch(mediaActions.getFile({ id })));
    }

    clear(): void {
        this.store.dispatch(mediaActions.clear());
    }
}
