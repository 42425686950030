export const environment = {
    production: false,
    sw: false,
    version: '1.0.0',
    serverUrl: 'https://api.adm.sandbox.cloud.codelabs.work/',
    uploadUrl: 'https://api.adm.sandbox.cloud.codelabs.work/api/v1/files/upload',
    assetsUrl: 'https://api.adm.sandbox.cloud.codelabs.work/',
    defaultLanguage: 'pl-PL',
    supportedLanguages: ['pl-PL', 'en-EN'] as const,
    icons: 'assets/svgs/icons.svg',
    cacheDurationInMinutes: 10,
    accountTypes: [
        { name: 'artist', value: 'artist' },
        { name: 'manager', value: 'manager' },
    ],
    analytics: {
        dateRanges: [
            { name: 'last-month', value: 'this-month' },
            { name: 'all-time', value: 'all-time' },
        ] as const,
        reportTypes: [
            { name: 'finance', value: 'finance' },
            { name: 'marketing', value: 'marketing' },
        ],
    },
    build: {
        time: true,
        commit: true,
    },
    socialLinks: ['tiktokLink', 'instagramLink', 'spotifyLink', 'youtubeLink'] as const,
    facebook: {
        configId: '868238858461521',
        appId: '3298524677121438',
    },
};
