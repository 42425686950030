import { CanDeactivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { SocialLinksFacade } from '../facades/social-links.facade';

export const socialLinksCleanup: CanDeactivateFn<unknown> = () => {
    const socialLinksFacade = inject(SocialLinksFacade);

    socialLinksFacade.clear();
    return true;
};
